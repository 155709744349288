import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoadingStateModel } from './loading.model';
import {
  SetActiveDialogLoading,
  SetOrganizationsLoading,
  SetOrganizationUsersLoading,
  SetUserEmailUpdating,
} from './loading.action';

@State<LoadingStateModel>({
  name: 'loadingState',
  defaults: {
    organizationsLoading: false,
    activeDialogLoading: false,
    userEmailUpdating: false,
    usersForOrganizationLoading: false,
  },
})
@Injectable()
export class LoadingState {
  @Selector()
  static organizationsLoading(state: LoadingStateModel): boolean {
    return state.organizationsLoading;
  }

  @Selector()
  static activeDialogLoading(state: LoadingStateModel): boolean {
    return state.activeDialogLoading;
  }

  @Selector()
  static userEmailUpdating(state: LoadingStateModel): boolean {
    return state.userEmailUpdating;
  }

  @Selector()
  static usersForOrganizationLoading(state: LoadingStateModel): boolean {
    return state.usersForOrganizationLoading;
  }

  @Action(SetOrganizationsLoading)
  setOrganizationsLoading(
    ctx: StateContext<LoadingStateModel>,
    action: SetOrganizationsLoading,
  ) {
    ctx.patchState({
      organizationsLoading: action.value,
    });
  }

  @Action(SetActiveDialogLoading)
  setActiveDialogLoading(
    ctx: StateContext<LoadingStateModel>,
    action: SetOrganizationsLoading,
  ) {
    ctx.patchState({
      activeDialogLoading: action.value,
    });
  }

  @Action(SetUserEmailUpdating)
  setUserEmailUpdating(
    ctx: StateContext<LoadingStateModel>,
    action: SetOrganizationsLoading,
  ) {
    ctx.patchState({
      userEmailUpdating: action.value,
    });
  }

  @Action(SetOrganizationUsersLoading)
  setOrganizationUsersLoading(
    ctx: StateContext<LoadingStateModel>,
    action: SetOrganizationsLoading,
  ) {
    ctx.patchState({
      usersForOrganizationLoading: action.value,
    });
  }
}
